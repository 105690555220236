<template>
  <v-card-text class="pa-0 elevation-0">
    <v-toolbar flat color="transparent">
      <v-icon class="grey--text mr-3">mdi-briefcase-clock-outline</v-icon>
      <h2 class="heading font-weight-normal grey--text">{{ $t('offers.title') }}</h2>
      <v-spacer></v-spacer>
      <v-icon
        id="btnTable"
        @click="activeView($event)"
        class="mr-6"
        :class="[Offers.state.isTable ? 'active' : '']"
        >mdi-format-list-bulleted-square</v-icon
      >
      <v-icon
        id="btnGrid"
        @click="activeView($event)"
        class="mr-6"
        :class="[!Offers.state.isTable ? 'active' : '']"
        >mdi-view-grid-outline</v-icon
      >
      <v-tooltip :bottom="true">
        <template v-slot:activator="{ on }">
          <v-badge
            :value="Object.keys(Offers.state.filters).length"
            :content="Object.keys(Offers.state.filters).length"
            overlap
            color="blue lighten-1"
          >
            <v-btn v-on="on" @click="show = !show" depressed small class="ml-2">
              <v-icon>{{ show ? 'mdi-filter-minus-outline' : 'mdi-filter-plus-outline' }}</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>{{
          Object.keys(Offers.state.filters).length
            ? `${Object.keys(Offers.state.filters).length} filters have been applied`
            : 'No filters applied yet'
        }}</span>
      </v-tooltip>
    </v-toolbar>
    <transition name="scale-transition">
      <v-divider v-if="show" class="mx-5"></v-divider>
    </transition>
    <transition name="scale-transition">
      <v-card v-if="show" color="transparent" elevation="0" class="ma-0 pa-0">
        <v-card-text>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6">
                <span class="subtitle-1 font-weight-light">Offer ID</span>
                <v-text-field
                  :value="Offers.state.filters._id"
                  @input="resetPagination('_id', $event)"
                  label="Offer ID"
                  clearable
                  flat
                  dense
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-pen"
                ></v-text-field>
                <span class="subtitle-1 font-weight-light">Offer Name</span>
                <v-text-field
                  :value="Offers.state.filters.name"
                  @input="resetPagination('name', $event)"
                  label="Offer Name"
                  clearable
                  flat
                  dense
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-pen"
                ></v-text-field>
                <span class="subtitle-1 font-weight-light">Categories</span>
                <v-autocomplete
                  v-model="Offers.state.filters._categories"
                  :items="Categories.state.items"
                  @input="resetPagination('_categories', $event)"
                  item-text="name"
                  item-value="_id"
                  label="Categories"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        resetPagination(
                          '_categories',
                          removeOnList(Offers.state.filters._categories, data.item._id)
                        )
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Payout Type</span>
                <v-autocomplete
                  v-model="Offers.state.filters.payoutType"
                  :items="payoutTypes"
                  item-text="name"
                  item-value="value"
                  label="Payout Type"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="resetPagination('payoutType', $event)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        resetPagination(
                          'payoutType',
                          removeOnList(Offers.state.filters.payoutType, data.item.value)
                        )
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6">
                <span class="subtitle-1 font-weight-light">Country</span>
                <v-autocomplete
                  v-model="Offers.state.filters._country"
                  :items="Countries.state.items"
                  item-text="name"
                  item-value="code"
                  label="Country"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="resetPagination('_country', $event)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        resetPagination(
                          '_country',
                          removeOnList(Offers.state.filters._country, data.item.code)
                        )
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Device Client</span>
                <v-autocomplete
                  v-model="Offers.state.filters.deviceClient"
                  :items="Devices.state.DeviceClientItems"
                  :loading="Devices.state.DeviceClientItemsLoading"
                  label="Device Client"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="resetPagination('deviceClient', $event)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        resetPagination(
                          'deviceClient',
                          removeOnList(Offers.state.filters.deviceClient, data.item)
                        )
                      "
                      class="ma-1"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Device Os</span>
                <v-autocomplete
                  v-model="Offers.state.filters.deviceOs"
                  :items="Devices.state.DeviceOsItems"
                  :loading="Devices.state.DeviceOsItemsLoading"
                  label="Device Os"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="resetPagination('deviceOs', $event)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        resetPagination(
                          'deviceOs',
                          removeOnList(Offers.state.filters.deviceOs, data.item)
                        )
                      "
                      class="ma-1"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Device Type</span>
                <v-autocomplete
                  v-model="Offers.state.filters.deviceType"
                  :items="Devices.state.DeviceTypeItems"
                  :loading="Devices.state.DeviceTypeItemsLoading"
                  label="Device Type"
                  multiple
                  solo
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="resetPagination('deviceType', $event)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="
                        resetPagination(
                          'deviceType',
                          removeOnList(Offers.state.filters.deviceType, data.item)
                        )
                      "
                      class="ma-1"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <span class="subtitle-1 font-weight-light">Offer Status</span>
                <v-autocomplete
                  v-model="Offers.state.filters.validatedStatus"
                  :items="offerStatus"
                  item-text="name"
                  item-value="value"
                  label="Offer Status"
                  multiple
                  chips
                  hide-details
                  dense
                  flat
                  solo-inverted
                  clearable
                  prepend-inner-icon="mdi-cursor-pointer"
                  @input="resetPagination('validatedStatus', $event)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                      @click:close="
                        resetPagination(
                          'validatedStatus',
                          removeOnList(Offers.state.filters.validatedStatus, data.item.value)
                        )
                      "
                      class="ma-1"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </transition>
  </v-card-text>
</template>
<script>
import { inject, onMounted, reactive } from '@vue/composition-api';
import _ from 'lodash';

export default {
  name: 'offer-filters',
  components: {},
  setup(props, { attrs, slots, emit, expose }) {
    const Categories = inject('Categories');
    const Countries = inject('Countries');
    const Offers = inject('Offers');
    const Devices = inject('Devices');
    let idView = '';
    const self = reactive({
      show: Object.keys(Offers.state.filters).length,
      payoutTypes: [
        { name: 'CPA', value: 'PER_ACTION' },
        { name: 'CPS', value: 'PER_SALE' },
        { name: 'CPL', value: 'PER_CLICK' },
        { name: 'CPA & CPS', value: 'PER_ACTION_AND_PER_SALE' },
      ],
      offerStatus: [
        { name: 'Approved', value: 'Approved' },
        { name: 'Apply to run', value: 'Apply to run' },
        { name: 'Applied', value: 'Applied' },
        { name: 'Rejected', value: 'Rejected' },
      ],
    });

    const resetPagination = _.debounce((key, value) => {
      Offers.actions.applyFilter({ key: key, value: value });
      emit('resetPagination');
    }, 1200);

    const removeOnList = (list, value) => {
      const index = _.indexOf(list, value);
      if (index >= 0) {
        list.splice(index, 1);
      }
      return list;
    };
    const activeView = (e) => {
      idView = e.currentTarget.id.replace('btn', '').toLowerCase();
      if (idView === 'table') {
        setOffersView(idView);
        emit('setOffersView', true);
      } else {
        setOffersView(idView);
        emit('setOffersView', false);
      }
    };
    function setOffersView(id) {
      if (id === 'table') {
        Offers.actions.setOffersView(true);
      } else {
        Offers.actions.setOffersView(false);
      }
    }

    onMounted(() => {
      Categories.actions.loadItems();
      Countries.actions.loadItems();
    });
    return {
      ...self,
      Categories,
      Countries,
      Offers,
      Devices,
      removeOnList,
      resetPagination,
      activeView,
      setOffersView,
    };
  },
};
</script>
<style lang="scss">
.theme--light.v-icon {
  &:after {
    border-radius: 0;
  }
  &.active {
    color: #42a5f5;
    pointer-events: none;
  }
}
</style>
